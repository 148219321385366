import {Col, Form, Row} from "react-bootstrap";
import IconTooltip from "../../Layout/IconsTooltip";
import {AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineEdit} from "react-icons/ai";
import Autocomplete from "../../Layout/Autocomplete";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {addressAutocomplete, cityAutocomplete, getPostalCode, validatePostalCode} from "../../../Api/Geo";
import debounce from "../../../Utils/Debounce";
import useSessionToken from "../../../Hooks/useSessionToken";
import authContext from "../../../Contexts/authContext";

const ResidenceInfo = (props) => {
    const [cityValue, setCityValue] = useState('');
    const [city, setCity] = useState([]);
    const [addressValue, setAddressValue] = useState('');
    const [address, setAddress] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [selectAddressValue, setSelectAddressValue] = useState('');
    const [addressId, setAddressId] = useState(0);
    const [postalCode, setPostalCode] = useState('');
    const [isAutocompleteEnabled, setIsAutocompleteEnabled] = useState(true);
    const [additionalData, setAdditionalData] = useState({});
    const [sessionToken, refreshSessionToken] = useSessionToken();
    const [skipAddress, setSkipAddress] = useState(false)

    const debouncedAddressAutocomplete = useCallback(debounce(addressAutocomplete, 500), []);

    useEffect(() => {
        setSelectValue(props.patientDetail && props.patientDetail.addresses ? props.patientDetail.addresses[0].city : '')
        setSelectAddressValue(props.patientDetail && props.patientDetail.addresses ? props.patientDetail.addresses[0].address : '')
        setPostalCode(props.patientDetail && props.patientDetail.addresses ? props.patientDetail.addresses[0].cap : '')
        setAddressId(props.patientDetail && props.patientDetail.addresses ? props.patientDetail.addresses[0].placeId : 0)
        setSkipAddress(JSON.parse(localStorage.getItem('userData'))?.skip_address ?? false)
    }, [props.patientDetail, props.edit])

    useEffect(() => {
        if (cityValue.length >= 3) {
            cityAutocomplete(cityValue, setCity)
        }
    }, [cityValue])

    useEffect(() => {
        if (addressValue.length >= 3) {
            debouncedAddressAutocomplete(addressValue, selectValue, setAddress, sessionToken)
        }
    }, [addressValue])

    useEffect(() => {
        if(addressId != 0 && addressId != null){
            getPostalCode(addressId, setPostalCode, props.setErrors, 'addresses.0.cap', setAdditionalData, sessionToken)
            refreshSessionToken()
        }
    }, [addressId])

    useEffect(() => {
        if (postalCode !== ''){
            validatePostalCode(postalCode, selectValue, props.setErrors, 'addresses.0.cap')
        }
    }, [postalCode]);

    //setta il valore selezionato dall'autocomplete
    const handleOnSelect = (e, type = 'city') => {
        if(type === 'city') {
            setCity([]);
            setCityValue([])

            /*Auto compilo i campi automaticamente*/
            document.querySelector('#city_res').value = e.city_name_ita
            document.querySelector('#res_prov').value = e.prov_code
            document.querySelector('#res_region').value = e.region_name

            setSelectValue(e.city_name_ita)
        }else{
            setAddress([]);
            setAddressValue([])

            /*Auto compilo i campi automaticamente*/
            document.querySelector('#address_res').value = e.main_text
            setSelectAddressValue(e.main_text)
            setAddressId(e.place_id)
        }
    }

    //setta il valore precedente se non selezionato un valore nella lista
    const onFocusOut = (e, type = 'city') => {
        const span = document.createElement('span')
        span.classList.add('error_field');

        if (document.querySelector(type === 'city' ? '#city_res' : '#address_res').value === '') {
            document.querySelector(type === 'city' ? '#city_res' : '#address_res').before(span)
            document.querySelector(type === 'city' ? '#city_res' : '#address_res').value = type === 'city' ? selectValue : selectAddressValue
            span.innerHTML = type === 'city' ? 'Seleziona una città dalla lista' : 'Seleziona un indirizzo dalla lista';
        } else {
            document.querySelector(type === 'city' ? '#city_res' : '#address_res').value = type === 'city' ? selectValue : selectAddressValue
            for (let i of document.querySelector(type === 'city' ? '#city_res' : '#address_res').parentNode.parentNode.getElementsByClassName('error_field')) {
                i.remove()
            }
        }

        setTimeout(() => {
            setCity([]);
            setAddress([]);
            props.setErrors({})
        }, 350)
    }

    const handleOnChange = (e, type = 'city') => {
        type === 'city' ? setCityValue(e.currentTarget.value) : setAddressValue(e.currentTarget.value)
    }

    //check phone changing if have a digit not number
    const handleCapChange = (e) => {
        e.target.value = e.target.value.replace(/^(\d{5}).*|[^0-9]/g, '$1'); // Remove non-digits and digits after 5 character
        setPostalCode(e.target.value)
    };

    //block digit non number oh input cap
    const handleCapKeyDown = (e) => {
        const allowedKeys = ['ArrowLeft', 'ArrowRight', 'Tab', 'Backspace'];

        // Block non-numeric characters
        if (!allowedKeys.includes(e.key) && (e.key && !/^\d$/.test(e.key))) {
            e.preventDefault();
        }
    };


    return (<Row className={'rounded-3 fs-6 px-0 searchForm_container mx-0 res_box box-2 d-flex align-content-start'}>
        <Form.Control type={'hidden'} name={'addresses[0][type]'} value={0}></Form.Control>
        <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
            <Row>
                <Col md={8}>
                    <p className={'mb-0 py-2'}>Indirizzo di residenza</p>
                </Col>
                <Col md={4}
                     className={'d-flex justify-content-end align-items-center position-relative'}>
                    {props.patientDetail &&
                        <>
                            <IconTooltip action={props.handleEdit} title="Modifica" id="t-1">
                                <span className={'reading box-2'}>
                                    <AiOutlineEdit className={'fs-3 text-second d-block'} data-id={'2'}></AiOutlineEdit>
                                </span>
                            </IconTooltip>
                            <span className={'editMode box-2 d-none'}>
                                <AiOutlineCloseCircle onClick={props.handleEdit} className={'fs-3 text-danger d-inline mx-2'} data-id={'2'}></AiOutlineCloseCircle>
                                <AiOutlineCheckCircle onClick={props.handleSavePatient} className={'fs-3 text-success d-inline'} data-id={'2'}></AiOutlineCheckCircle>
                            </span>
                        </>
                    }
                </Col>
            </Row>
        </Col>

        <Row className={'py-4 px-4'}>
            <Form.Group className={'my-2'}>
                <Row>
                    <Form.Label className={'col-md-3'}>Città*</Form.Label>

                    <Col className={'col-md-9'}>

                        <Autocomplete id={'city_res'} name={'addresses[0][city]'} data-error={'addresses.0.city'}
                                      action={handleOnChange}
                                      selectAction={handleOnSelect}
                                      item={city}
                                      dataId={2}
                                      mappingProps={'city_prov'}
                                      required={true}
                                      onFocusOut={onFocusOut}
                                      disabled={props.patientDetail && 'disabled'}
                                      defaultValue={props.patientDetail && props.patientDetail.addresses ? props.patientDetail.addresses[0].city : ''}

                        >
                        </Autocomplete>


                    </Col>
                </Row>

            </Form.Group>


            <Form.Group className={' my-2'}>
                <Row>
                    <Form.Label className={'col-md-3'}>Provincia*</Form.Label>
                    <Col className={'col-md-9 '}>
                        <Form.Control name="addresses[0][province]" id="res_prov" data-error={'addresses.0.province'}
                                      type="text"
                                      className={'d-inline form-control-sm'}
                                      required
                                      readOnly={'readonly'}
                                      onChange={props.handleFormDetailChange}
                                      disabled={props.patientDetail && 'disabled'}
                                      defaultValue={props.patientDetail && props.patientDetail.addresses ? props.patientDetail.addresses[0].province : ''}

                        />
                    </Col>
                </Row>

            </Form.Group>

            <Form.Group className={'my-2'}>
                <Row>
                    <Form.Label className={'col-md-3'}>Regione*</Form.Label>
                    <Col className={'col-md-9 '}>
                        <Form.Control name="addresses[0][region]" id="res_region" type="text"
                                      data-error={'addresses.0.region'}
                                      className={'d-inline form-control-sm'}
                                      required
                                      readOnly={'readonly'}
                                      onChange={props.handleFormDetailChange}
                                      disabled={props.patientDetail && 'disabled'}
                                      defaultValue={props.patientDetail && props.patientDetail.addresses ? props.patientDetail.addresses[0].region : ''}

                        />
                    </Col>
                </Row>
            </Form.Group>

            <input type={'hidden'} name={'addresses[0][placeId]'} value={addressId}/>

            <Form.Group className={' my-2'}>
                <Row>
                    <Form.Label className={'col-md-3'}>Indirizzo*</Form.Label>
                    <Col className={'col-md-9 '}>
                        {isAutocompleteEnabled ? (
                            <Autocomplete id={'address_res'} name={'addresses[0][address]'}
                                          data-error={'addresses.0.address'}
                                          action={(e) => handleOnChange(e, 'address')}
                                          selectAction={(e) => handleOnSelect(e, 'address')}
                                          item={address}
                                          dataId={2}
                                          mappingProps={'description'}
                                          required={true}
                                          onFocusOut={(e) => onFocusOut(e, 'address')}
                                          disabled={props.patientDetail && props.isEdit && 'disabled'}
                                          defaultValue={props.patientDetail && props.patientDetail.addresses ? props.patientDetail.addresses[0].address : ''}
                            >
                            </Autocomplete>
                        ) : (
                            <Form.Control id={'address_res'} name={'addresses[0][address]'}
                                          data-error={'addresses.0.address'}
                                          type="text"
                                          className={'d-inline form-control-sm'}
                                          disabled={props.patientDetail && props.isEdit && 'disabled'}
                                          required
                                          onChange={(e) => setAddressValue(e.target.value)}
                                          value={addressValue}
                            />
                        )}
                    </Col>
                </Row>

            </Form.Group>

            <Form.Group className={'my-2'}>
                <Row>
                    <Form.Label className={'col-md-3'}>CAP*</Form.Label>
                    <Col className={'col-md-9 '}>
                        <Form.Control name="addresses[0][cap]" type="text" data-error={'addresses.0.cap'}
                                      className={'d-inline form-control-sm'} required
                                      disabled={props.patientDetail && 'disabled'}
                                      onKeyDown={handleCapKeyDown}
                                      onChange={handleCapChange}
                                      value={postalCode}

                        />
                    </Col>
                </Row>
            </Form.Group>

            {skipAddress && (
                <>
                    <Form.Group className={'my-2'}>
                        <Row>
                            <Form.Label className={'col-md-10'}>Autocomplete Google</Form.Label>
                            <Col className={'col-md-2'}>
                                <Form.Switch
                                    label={isAutocompleteEnabled ? "Sì" : "No"}
                                    id="autocompleteSwitch"
                                    checked={isAutocompleteEnabled}
                                    name={'radioAutocomplete_0'}
                                    value={Number(isAutocompleteEnabled)}
                                    onChange={() => {
                                        setIsAutocompleteEnabled(!isAutocompleteEnabled);
                                        setAddressId(0)
                                        setAddress('')
                                        setAdditionalData({})
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form.Group>

                    <input type={'hidden'} name={'addresses[0][autocomplete]'} value={Number(isAutocompleteEnabled)}/>
                </>
            )}

            <input type={'hidden'} name={'addresses[0][additional_data]'} value={JSON.stringify(additionalData)}/>
        </Row>
    </Row>)
}

export default ResidenceInfo